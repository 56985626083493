import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Grid, Card, CircularProgress, Breadcrumbs, Link } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  fetchDashBoard,
  getDashBoardContent,
  getDepartmentVsPrority,
  getStatusVsPriority,
  getDepartmentStatus,
  getProrityList,
  getStatusList,
  getAssmentHasTask,
  getActivityTypesTasks,
} from "../../store";
import { AppErrorBoundry } from "../../components";
import { STATUS_COLOR_CODE, getBarOptions, getPIEColors } from "./charts";
import "./dashboard.scss";
import { PieChart } from "./pie";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
defaults.font.family = "Exo 2";
defaults.font.size = 14;

const TableColumnsDeptWise = [
  { key: "department", label: "Department" },
  { key: "total", label: "OVERALL" },
];
const TableColumnsStatusWise = [
  { key: "department", label: "Status" },
  { key: "total", label: "OVERALL" },
];

const DashBoardTable = (props) => {
  const { columns, data, heading, className } = props;
  return (
    <Card>
      <div className={`tbl-cont ${className || ""}`}>
        <div className="tbl-title">{heading}</div>
        <table>
          <thead className="tbl-header">
            <tr>
              {columns.map((_head) => {
                return (
                  <th key={_head.key}>
                    <span>{_head.label}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="tbl-body">
            {data.map((_tRow, index) => {
              return (
                <tr key={index}>
                  {columns.map((_head) => {
                    return (
                      <td key={_head.key || _head.id}>
                        <span>
                          {_tRow[_head.key || _head.id || _head.label] || "-"}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
const getDepartmentWiseVolumne = (deptVolume) => {
  if (Array.isArray(deptVolume)) {
    let pieData = [],
      total = 0,
      colors = getPIEColors(deptVolume.length);
    deptVolume.forEach((item, i) => {
      if (item.department !== "OVERALL") {
        // pieData.push(item.total);
        // labels.push(item.department);
        total += item.total;
        pieData.push({
          id: item.department,
          label: item.department,
          value: item.total,
          color: colors[i],
        });
      }
    });
    pieData = pieData.map((_d) => {
      return { ..._d, percentage: Number((_d.value / total).toFixed(2)) * 100 };
    });
    // return { labels: labels, datasets: [{ label: "# of Votes", data: [...pieData], backgroundColor: colors }] }
    return { data: pieData, total: total };
  }
  return null;
};
const getStatusWiseTaskPerSource = (data, status) => {
  if (
    Array.isArray(data) &&
    data.length > 0 &&
    Array.isArray(status) &&
    status.length > 0
  ) {
    let datasets = [],
      labels = [],
      colors = getPIEColors(data.length),
      totals = [{ key: "Total", value: 0 }],
      totalDataSet = {
        label: "Total",
        data: [],
        barPercentage: 0.5,
        backgroundColor: STATUS_COLOR_CODE.Total,
      };

    data.forEach((item, i) => {
      let dataset = {
        label: item.label,
        data: [],
        barPercentage: 0.5,
        backgroundColor: colors[i],
      };
      totals[0].value += item.overall;
      status.forEach(({ id, label }, j) => {
        let statusIndex = j + 1;
        let row = item.rows.find((_) => {
          return _.label === label;
        });
        let value = row ? row.total : 0;
        if (!totals[statusIndex])
          totals[statusIndex] = { key: label, value: 0 };
        totals[statusIndex].value += value;
        dataset.data.push(value);
      });
      dataset.data.unshift(item.overall);
      datasets.push(dataset);
    });
    let max = 0;
    totals.forEach(({ value, key }, i) => {
      if (value !== 0) {
        totalDataSet.data.push(value);
        max += value;
        labels.push(key);
      }
    });
    datasets.forEach((_, i) => {
      datasets[i].data = datasets[i].data.filter((val, j) => {
        return totals[j].value !== 0;
      });
    });

    datasets = [totalDataSet, ...datasets];
    return { datasets: datasets, labels: [...labels], total: max };
  }
  return false;
};
const getSourceWiseTaskStatus = (data, status) => {
  if (Array.isArray(data) && data.length > 0 && Array.isArray(status)) {
    let total = 0,
      labels = [],
      pieData = [],
      barDatasets = [],
      allTotal = [],
      colors = getPIEColors(data.length);

    let chartData = { datasets: [] };
    let totalSet = {
      label: "Total",
      barPercentage: 0.5,
      data: [],
      backgroundColor: STATUS_COLOR_CODE.Total,
      skipNull: true,
    };
    data.forEach((item, i) => {
      total += item.overall;
      labels.push(item.label);
      allTotal.push(item.overall);
      pieData.push({
        id: item.label,
        label: item.label,
        value: item.overall,
        color: colors[i],
      });
    });

    status.forEach(({ id, label }) => {
      let set = {
        label: label,
        barPercentage: 0.5,
        data: [],
        backgroundColor: STATUS_COLOR_CODE[id],
        skipNull: true,
      };
      let allNull = true,
        statusTotal = 0;
      data.forEach((item, i) => {
        let index = item.rows.findIndex((row) => {
          return row.label === label;
        });
        let value = index > -1 ? item.rows[index].total : null;
        set.data.push(value);
        if (value !== null) {
          allNull = false;
          statusTotal += value;
        }
      });
      set.data.unshift(statusTotal > 0 ? statusTotal : null);
      if (!allNull) {
        barDatasets.push(set);
      }
    });
    let barLabels = labels.map((_l) => {
      return _l.split(" ");
    });
    totalSet.data = [total, ...allTotal];
    chartData.bar = {
      labels: [["Total"], ...barLabels],
      datasets: [totalSet, ...barDatasets],
    };
    chartData.pie = { data: pieData, total: total };
    chartData.max = total + 1;
    // console.log('totalSet', data, totalSet, chartData);
    return chartData;
  }
  return null;
};
const DashBoardScreen = (props) => {
  const [chartWidth, setChartWidth] = useState(0);
  const _chatRef = useRef(null);
  const {
    departmentWiseStatus,
    statusVsPriorty,
    departmentVsPriorty,
    sources,
    activityTypes,
  } = props;
  const allSource = [...(sources || []), ...(activityTypes || [])];
  const sourceVsStatusBar = getSourceWiseTaskStatus(
    allSource,
    props.statusList
  );
  const deptWiseVolume = getDepartmentWiseVolumne(departmentWiseStatus);
  const tasksPerSource = getStatusWiseTaskPerSource(
    allSource,
    props.statusList
  );
  const updatedStatusVsPriority = (statusVsPriorty || []).map((row) => {
    return {
      ...row,
      Medium: (row.Medium || 0) + (row.Default || 0), // Add Default to Medium
    };
  });
  useEffect(() => {
    document.title = "Arc | Stats";
    props.fetchDashBoard();
  }, []);
  useEffect(() => {
    if (_chatRef.current) {
      setChartWidth(_chatRef.current.offsetWidth);
      // console.log('_chatRef.current', _chatRef.current.offsetWidth);
    }
  }, [_chatRef.current]);
  return (
    <div className="col full dash-view">
      {props.isDashBoardContentReady ? (
        <>
          {/* <div className='breadcrumb-envelop'>
              <Breadcrumbs aria-label="breadcrumb" classes={{ li: 'h7 exo2 breadcrumb' }}>
                <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href={"/T" + window.location.search} >Tracker</Link>
                <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href={"/S"} >Stats</Link>
              </Breadcrumbs>
            </div> */}
          <Grid container spacing={2}>
            {Boolean(departmentWiseStatus) && Boolean(props.statusList) && (
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <DashBoardTable
                  columns={[...TableColumnsDeptWise, ...props.statusList]}
                  data={departmentWiseStatus}
                  heading="Department-wise Remediation & Compliance Status"
                />
              </Grid>
            )}
            {Boolean(departmentVsPriorty) && Boolean(props.priorityList) && (
              <Grid item xs={12} md={6} sm={12} lg={6}>
                <DashBoardTable
                  columns={[...TableColumnsDeptWise, ...props.priorityList]}
                  data={departmentVsPriorty}
                  heading="Department vs Priority"
                />
              </Grid>
            )}
            {Boolean(statusVsPriorty) && Boolean(props.priorityList) && (
              <Grid item xs={12} md={6} sm={12} lg={6}>
                <DashBoardTable
                  columns={[...TableColumnsStatusWise, ...props.priorityList]}
                  data={updatedStatusVsPriority ? updatedStatusVsPriority : []}
                  heading="Compliance Status vs Priority"
                />
              </Grid>
            )}
            {/* {
                Boolean(deptWiseVolume) &&
                <Grid item xs={12} md={6} sm={12} lg={6}>
                  <Card className='chart-card'>
                    <div className='exo2 h5 rmedimum title'>Department-wise Task Volume</div>
                    <div className='col flex-ctr pie-cont' ref={_chatRef}>
                      <AppErrorBoundry message="Unable to load the chart">
                        <PieChart width={chartWidth} {...deptWiseVolume} className="pie" />
                      </AppErrorBoundry>
                    </div>
                  </Card>
                </Grid>
              } */}
            {/* {
                Boolean(sourceVsStatusBar) &&
                <>
                  <Grid item xs={12} md={6} sm={12} lg={6}>
                    <Card className='chart-card'>
                      <div className='exo2 h5 rmedimum title'>Source-wise Task Volume</div>
                      <div className='col flex-ctr pie-cont'>
                        <AppErrorBoundry message="Unable to load the chart">
                          <PieChart width={chartWidth} {...sourceVsStatusBar.pie} className="pie" />
                        </AppErrorBoundry>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12} lg={6}>
                    <Card className='chart-card'>
                      <div className='exo2 h5 rmedimum title'>Source-wise Task Status</div>
                      <AppErrorBoundry message="Unable to load the chart">
                        <Bar data={sourceVsStatusBar.bar} options={getBarOptions(sourceVsStatusBar.max)} />
                      </AppErrorBoundry>

                    </Card>
                  </Grid>
                </>
              } */}
            {/* {
                Boolean(tasksPerSource) &&
                <Grid item xs={12} md={6} sm={12} lg={6}>
                  <Card className='chart-card'>
                    <div className='exo2 h5 rmedimum title'>Status-wise Tasks per Source</div>
                    <AppErrorBoundry message="Unable to load the chart">
                      <Bar data={tasksPerSource} options={getBarOptions(tasksPerSource.total + 1, true)} />
                    </AppErrorBoundry>
                  </Card>
                </Grid>
              } */}
          </Grid>
        </>
      ) : (
        <div className="col full flex-ctr">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashBoard: () => dispatch(fetchDashBoard()),
  };
};
const mapStateToProps = (state) => {
  return {
    isDashBoardContentReady: Boolean(getDashBoardContent(state)),
    departmentWiseStatus: getDepartmentStatus(state),
    statusVsPriorty: getStatusVsPriority(state),
    departmentVsPriorty: getDepartmentVsPrority(state),
    priorityList: getProrityList(state),
    statusList: getStatusList(state),
    sources: getAssmentHasTask(state),
    activityTypes: getActivityTypesTasks(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardScreen);
